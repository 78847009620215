<template>
    <div class="add-goods-page">
        <div class="title">
            <span>{{this.$route.params.isEdit === 'true' ? '编辑' : '添加'}}商品</span>
        </div>
        <div>
            <el-form :model="addGoodsData" ref="addGoodsForm" status-icon :rules="rules" label-width="86px">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="addGoodsData.title" size="medium" ></el-input>
                </el-form-item>
                <el-form-item label="副标题">
                    <el-input v-model="addGoodsData.subtitle" size="medium" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="商品分类" prop="good_type"
                :rules="[{ required: true, message: '请选择分类', trigger: 'change' }]">
                     <el-select v-model="addGoodsData.good_type" size="medium" clearable placeholder="请选择">
                        <el-option
                        v-for="(item, index) in goodsTypeList"
                        :key="index"
                        :label="item"
                        :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="标签">
                    <el-input v-model="addGoodsData.tag" size="medium" autocomplete="off"></el-input>
                    <div style="line-height: 20px;color: #aaa;font-size: 12px;">商品标签用于区分同一个分类下的不同小类，请认真填写</div>
                </el-form-item> -->
                <el-form-item label="商品排序">
                    <el-input-number v-model="addGoodsData.sort" size="medium" :min="0" :max="99999" label="输入排序"></el-input-number>
                    <div style="line-height: 20px;color: #aaa;font-size: 12px;">数字越小排序越靠前</div>
                </el-form-item>
                <el-form-item label="价格">
                    <el-input-number v-model="addGoodsData.price" :min="0" :max="1000000" size="medium" autocomplete="off"></el-input-number>
                    <div class="note">单位为分，1元为100分</div>
                </el-form-item>

                <el-form-item label="图片" style="margin-bottom: 10px;">
                    <el-upload
                        class="avatar-uploader"
                        action="/api/admin/api/v2/update/file"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="addGoodsData.images" :src="addGoodsData.images" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="多规格">
                    <el-table class="el-table-container"
                        :data="addGoodsData.good_item"
                        style="width: 800px">
                        <el-table-column
                        align="center"
                        label="图片">
                        <template slot-scope="scope">
                            <el-upload
                                class="avatar-uploader"
                                action="/api/admin/api/v2/update/file"
                                :show-file-list="false"
                                :on-success="(res, file) => itemImgSuccess(res, file, scope.row)"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="scope.row.image" :src="scope.row.image" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </template>
                        </el-table-column>
                        <el-table-column
                        align="center"
                        label="标题">
                        <template slot-scope="scope">
                            <el-input  class="table-input" v-model="scope.row.title" size="medium" ></el-input>
                        </template>
                        </el-table-column>
                        <el-table-column
                        align="center"
                        label="价格">
                        <template slot-scope="scope">
                            <el-input-number class="table-input" v-model="scope.row.price" :min="0" :max="1000000" size="medium" autocomplete="off"></el-input-number>
                        </template>
                        </el-table-column>
                        <el-table-column width="100" label="操作">
                        <template slot-scope="scope">
                            <!-- <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            -->
                             <el-popconfirm
                                confirmButtonText='确定'
                                cancelButtonText='不用了'
                                icon="el-icon-info"
                                iconColor="red"
                                @onConfirm="deleteGoodsItem(scope.$index, scope.row)"
                                title="确定删除该商品规格吗？">
                                <el-button slot="reference" type="text" size="small">删除</el-button>
                            </el-popconfirm>
                        </template>
                        </el-table-column>
                    </el-table>
                    <el-button size="mini" type="primary" @click="addGoodsItem">增加规格</el-button>
                </el-form-item>
                <el-form-item label="详情">
                    <wangEditor v-model="addGoodsData.details" style="width: 600px;"></wangEditor>
                </el-form-item>
            </el-form>
            <div style="padding: 14px 80px;">
                  <el-button type="primary" size="small" @click="submitGoods('addGoodsForm')"> 保 存 </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import wangEditor from '@/components/wangEditor.vue'
export default {
    name: 'AddGoods',
    components: { wangEditor },
    data () {
        return {
            // 验证规则
            rules: {
                title: [
                    { required: true, message: '请输入标题', trigger: 'change' }
                ]
            },

            // 增加/编辑商品业务数据
            addGoodsData: {
                title: '',
                subtitle: '',
                images: '',
                details: '',
                price: '',
                good_type: '',
                // tag: '',
                sort: 0,
                good_item: []
            },

            goodsTypeList: [],
            // 新增/编辑数据
            addGoodsTypeData: {
                typeName: ''
            }
        }
    },
    mounted () {
        console.log(JSON.parse(localStorage.getItem('goodsEditData')))
        this.getGoodsTypeList()
        // 取参为字符串
        if (this.$route.params.isEdit === 'true') {
            // eslint-disable-next-line camelcase
            const { title, subtitle, images, details, price, good_id, good_type, good_item, sort } = { ...JSON.parse(localStorage.getItem('goodsEditData')) }
            this.addGoodsData = { title, subtitle, images, details, price, good_type, good_item }
            this.$set(this.addGoodsData, 'sort', sort || 0)
            // eslint-disable-next-line camelcase
            this.addGoodsData.good_id = good_id
            console.log(this.addGoodsData)
        }
    },
    methods: {
        // 上传图片成功回调
        handleAvatarSuccess (res, file) {
            this.addGoodsData.images = res.data.url
        },
        // 上传图片成功回调
        itemImgSuccess (res, file, itemInfo) {
            itemInfo.image = res.data.url
        },
        // 图片上传前验证，图片格式、大小等
        beforeAvatarUpload (file) {
            console.log(file.type)
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG/GIF 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
            }
            return isJPG && isLt2M
        },

        // 添加商品规格
        addGoodsItem () {
            this.addGoodsData.good_item.push({
                image: '',
                title: '',
                price: 0
            })
        },
        // 删除商品规格
        deleteGoodsItem (index) {
            this.addGoodsData.good_item.splice(index, 1)
        },

        // 获取商品分类列表
        getGoodsTypeList () {
            this.axios.post('/good/type/list').then((res) => {
                console.log(res)
                this.goodsTypeList = res.data
            })
        },

        submitGoods (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.$route.params.isEdit === 'true' ? '/good/update' : '/good/add'

                    this.axios.post(requestUrl, this.addGoodsData).then((res) => {
                        this.$router.push('/home/goods')
                    }).catch(() => {
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .add-goods-page{
        .title{
            padding: 14px;
            margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .el-input, .el-input-number{
            width: 260px;
        }

        .note{
            line-height: 20px;
            font-size: 14px;
            color: #d84040;
        }

        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 160px;
            height: 160px;
            line-height: 160px;
            text-align: center;
        }
        .avatar {
            width: 160px;
            height: 160px;
            display: block;
        }

        .el-table-container{
            .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 100px;
                height: 100px;
                line-height: 100px;
                text-align: center;
            }
            .avatar {
                width: 100px;
                height: 100px;
                display: block;
            }
        }

        .table-input{
            width: 200px;
            .el-input{
                width: 200px;
            }
        }

        .add-dialog{
            .el-input,.el-select{
                width: 240px;
            }

            .el-dialog__body{
                padding: 10px 20px;
            }
        }
    }
</style>
