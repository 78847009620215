<template>
    <div class="goods-type-page">
        <div class="title">
            外卖门店
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="openAddDialog(false)">添加门店</el-button>
            <div style="display: flex;">
                <el-input size='medium' v-model="querySelect" style="margin-right: 10px;"></el-input>
                <el-button type="primary" size="small" @click="searchList">搜索</el-button>
            </div>
        </div>

        <div class="table-container">
            <el-table border :data="storeList">
                <el-table-column prop="type_name" align="center" label="名称">
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                    <template slot-scope="scope">
                        {{ scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column prop="更新时间" align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{ scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column prop="上级分类" align="center" label="上级分类">
                    <template slot-scope="scope">
                        {{ scope.row.pid_name }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="update_user_name" align="center" label="最后更新者">
                   <template slot-scope="scope">
                       {{scope.row.update_user_name || scope.row.create_user_name}}
                   </template>
               </el-table-column> -->
                <el-table-column align="center" label="操作">
                     <template slot-scope="scope">
                     <span>
                        <el-button type="text" size="small" style="margin-right: 10px" @click="openAddDialog(true, scope.row)">编辑</el-button>
                    </span>
                    <span>
                         <el-popconfirm
                            confirmButtonText='好的'
                            cancelButtonText='不用了'
                            icon="el-icon-info"
                            iconColor="red"
                            @onConfirm="deleteStore(scope.row.good_type_id)"
                            title="确定删除该门店吗？">
                            <el-button slot="reference" type="text" size="small">删除</el-button>
                        </el-popconfirm>
                     </span>
                     </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total"
                           :page-size="size"
                           :current-page.sync="page" @current-change="getStoreList">
            </el-pagination>
        </div>

        <el-dialog :title="`${isEdit ? '编辑' : '新增'}门店`" class="add-dialog" :close-on-click-modal="false"
                   width="400px" :visible.sync="addStoreDialog">
            <el-form :model="addStoreData" :rules="rules" ref="addStoreForm" label-width="78px">
                <el-form-item label="上级分类" prop='pidTypeName'>
                    <el-select v-model="addStoreData.pid" placeholder="请选择">
                        <el-option
                            v-for="item in storeList"
                            :key="item.good_type_id"
                            :label="item.type_name"
                            :value="item.good_type_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分类名称" prop='typeName'>
                    <el-input v-model="addStoreData.typeName" size="medium"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addStoreDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureSaveStore('addStoreForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: 'Store',
    data () {
        return {
            // 验证规则
            rules: {
                typeName: [
                    { required: true, message: '请输入分类名称', trigger: 'change' }
                ]
            },

            // 门店列表
            storeList: [],
            page: 1,
            size: 10,
            total: 0,
            querySelect: '',

            // 新增/编辑数据
            addStoreData: {
                typeName: '',
                pid: ''
            },
            addStoreDialog: false,
            isEdit: false
        }
    },
    mounted () {
        this.getStoreList()
    },
    methods: {
        // 获取门店列表
        getStoreList () {
            this.axios.post('/good/type/list', {
                page: this.page,
                size: this.size,
                search: this.querySelect
            }).then((res) => {
                this.storeList = res.data.list
                // this.total = res.data[0].count
                console.log('商品列表')
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getStoreList()
        },

        /**
         * 打开弹窗
         * @param isEdit 是否为编辑
         * @param storeData 为编辑时，门店数据
         */
        openAddDialog (isEdit, storeData) {
            this.isEdit = isEdit
            this.addStoreDialog = true
            // this.addStoreData.pid = storeData.pid
            if (isEdit) {
                this.addStoreData = {
                    goodTypeId: storeData.good_type_id,
                    typeName: storeData.type_name,
                    pid: storeData.pid
                }
            } else {
                this.addStoreData = {
                    typeName: ''
                }

                this.$nextTick(() => {
                    this.$refs.addStoreForm.clearValidate()
                })
            }

            console.log(storeData)
        },

        /**
         * 删除门店
         * @param editStoreId 删除ID
         */
        deleteStore (editStoreId) {
            this.axios.post('/good/type/delete', {
                good_type_id: editStoreId
            }).then((res) => {
                if (res) {
                    this.getStoreList()
                    this.$message.success('删除成功')
                }
            })
        },

        /**
         * 保存门店数据
         * @param formName 表单名称
         */
        sureSaveStore (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.isEdit ? '/good/type/update' : '/good/type/add'
                    const { typeName, goodTypeId, pid } = { ...this.addStoreData }
                    console.log(pid)
                    this.axios.post(requestUrl, {
                        good_type_id: goodTypeId,
                        type_name: typeName,
                        pid: pid
                    }).then((res) => {
                        console.log(res)
                        this.addStoreDialog = false
                        this.$message.success(`成功${this.isEdit ? '编辑' : '添加'}门店`)
                        this.getStoreList()
                    }).catch(() => {
                        // console.log(error)
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
.goods-type-page {
    .title {
        padding: 14px;
        margin-bottom: 18px;
        border-bottom: 1px solid #dddddd;
        font-size: 28px;
    }

    .search-div {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #dddddd;
    }

    .table-container {
        margin: 10px 20px 0;
    }

    .page-pageination {
        padding: 10px 0;
        text-align: right;
    }

    .add-dialog {
        .el-input, .el-select {
            width: 240px;
        }

        .el-dialog__body {
            padding: 10px 20px;
        }
    }
}
</style>
